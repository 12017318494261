import { useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./payment.css";

import axios from "api/axios";
import { jwtDecode } from "jwt-decode";

export function ConfirmPage() {
  //confirm toss
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  const email = userInfo.email;
  const loggedin = localStorage.getItem("STATUS");
  const [isloading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
      email: email,
    };

    if (
      !searchParams.get("orderId")||
      !searchParams.get("amount")||
      !searchParams.get("paymentKey")||
      !email === "" || 
      loggedin !== "true"
    ) {
      navigate("/user/registration");
    } else {
      async function confirm() {
        try {
          await axios.post("/payment/confirmpayment", requestData);
          navigate("/userinfo");
        } catch (error) {
          console.error('Error confirming payment:', error);
          // Handle the error if needed
          setIsLoading(false);
          if (error.data.code === "INVALID_CARD_NUMBER") {
            setErrorMsg("The Card Number entered is not correct")
          } else {
            setErrorMsg("Something Went Wrong")
          }
        }
      }
      confirm();
    }
  }, [searchParams, email, loggedin, navigate]);

  return <div>
    {isloading ? (<div>Confirming Payment</div>) : (<div>{errorMsg}. Please try again at a later time.</div>)}
  </div>;
}
