/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images

import l71 from "assets/images/l71.png";
import l72 from "assets/images/l72.png";
import mercure1 from "assets/images/mercure1.png";
import mercure2 from "assets/images/mercure2.png";

function HotelInformation() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
          <RotatingCard>
              <RotatingCardFront
                image={l71}
                icon="touch_app"
                title={
                  <>
                   L7 Hotel  
                  </>
                }
                color="yonseiRoyalBlue"
              />
              <RotatingCardBack
                image={l72}
                title="View Hotel"
                color="yonseiRoyalBlue"
                action={{
                  type: "internal",
                  route: "/travel/l7hotel",
                  label: "View",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={mercure1}
                icon="touch_app"
                title={
                  <>
                    Mercure Ambassador Hotel
                  </>
                }
                color="yonseiGrey"
              />
              <RotatingCardBack
                image={mercure2}
                title="View Hotel"
                color="yonseiGrey"
                action={{
                  type: "internal",
                  route: "/travel/mercureambassadorhotel",
                  label: "View",
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HotelInformation;
