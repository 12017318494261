/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

import kthan from "assets/images/keynotes/kthan.png";
import mariacrui from "assets/images/keynotes/marianacrui.png";
import peterrijin from "assets/images/keynotes/peterrijin.png";
import swchoi from "assets/images/keynotes/swchoi.png";
import jihoon from "assets/images/localcomittee/jhr.png";
import wim from "assets/images/board/wim.png";
import chia from "assets/images/keynotes/Chia_Ling_Hsu.jpg";

import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

import React, { useEffect, useState } from "react";

function KeyNoteTeam() {
  const [hash, setHash] = useState("");

  useEffect(() => {
    const hashValue = window.location.hash.substring(1);
    if (hashValue) {
      setHash(hashValue);
    }
  }, []);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={20}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={0}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Keynotes
            </MKTypography>
            <MKTypography variant="body2" color="white">
              Note: All keynotes will be recorded. Please click read more to
              view each session information in more detail after the conference.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={wim}
                name="Wim. van der Linden"
                position={{
                  color: "info",
                  label: "University of Twente, Netherlands",
                }}
                description="Presidential Speech"
                // link="/keynotes/keynote1"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={swchoi}
                name="Seung W. Choi"
                position={{
                  color: "info",
                  label: "University of Texas at Austin, USA",
                }}
                description="Optimal test design approach to through-year computerized adaptive testing"
                link="/pages/keynotes/seungwchoi"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={mariacrui}
                name="Mariana Curi"
                position={{
                  color: "info",
                  label: "University of São Paulo, Brazil",
                }}
                description="Estimation of MIRT model parameters with deep learning"
                link="/pages/keynotes/marianacuri"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={kthan}
                name="Kyung (Chris) T. Han"
                position={{
                  color: "info",
                  label: "Graduate Management Admission Council®, USA",
                }}
                description="Enhancing Test-Taking Experience: Innovative CAT Designs for Improved Learning and Evaluation"
                link="/pages/keynotes/kyungthan"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={chia}
                name="Chia-Ling Hsu (Early Career Award Winner)"
                position={{
                  color: "info",
                  label: "Hong Kong Examinations and Assessment Authority",
                }}
                description="Variable-length adaptive multistage testing"
                link="/pages/keynotes/chialinghsu"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={peterrijin}
                name="Peter W. van Rijn"
                position={{ color: "info", label: "ETS Global, Netherlands" }}
                description="The Impact of Adaptive Testing Designs on Statistical and AI-Based Methods"
                link="/pages/keynotes/peterrijin"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={jihoon}
                name="Ji Hoon Ryoo"
                position={{
                  color: "info",
                  label: "Yonsei University, South Korea",
                }}
                description="AI-based AIG within a Learning Engineering Framework"
                link="/pages/keynotes/jihoonryoo"
              />
            </MKBox>
          </Grid>
        </Grid>
        <Grid
          container
          item
          pt={4}
          xs={12}
          lg={0}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Workshops
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <section id="Workshops1">
              <MKBox mb={1}>
                <DefaultReviewCard
                  color="light"
                  name="Duanli Yan (ETS) and Alina A. von Davier(Duolingo)"
                  date="Sept. 24 (Tuesday)"
                  title="Introduction to AI-based Automated Item Generation and Scoring in Adaptive Testing"
                  review='This workshop introduces a novel framework, "the item factory", for managing large-scale test development including automation of item generation, quality review, quality assurance, and crowdsourcing techniques in adaptive testing. We will present an overview of the latest natural language processing (NLP) techniques and large language models for automatic item generation, alongside evidence-centered design and psychometric principles and practices for test development. We will discuss the application of engineering principles in designing efficient item production processes (Luecht, 2008; Dede et al, 2018; von Davier, 2017).'
                  // rating={5}
                />
              </MKBox>
            </section>
          </Grid>
          <Grid item xs={12} lg={12}>
            <section id="Workshops2">
              <MKBox mb={1}>
                <DefaultReviewCard
                  color="light"
                  name="Kyung (Chris) T. Han (Graduate Management Admission Council®) and Nathan Thompson (ASC)"
                  date="Sept. 24 (Tuesday)"
                  title="Introduction to IRT and CAT"
                  review="This workshop provides a broad overview of item response theory (IRT) and computerized adaptive testing (CAT) for those who are newer to the field.  We assume a knowledge of basic psychometrics such as classical test theory.  The workshop begins with a background on (IRT), how it can be used to evaluate item and test performance, and how it provides a number of improvements over the classical approach.  We then provide an introduction to CAT, describing the components and algorithms necessary to build an effective CAT program: item bank calibrated with IRT, starting point, item selection rule, scoring method, and termination criterion.  Finally, we discuss important aspects regarding how you might evaluate and implement CAT for your organization."
                  // rating={5}
                />
              </MKBox>
            </section>
          </Grid>
          <Grid item xs={12} lg={12}>
            <section id="Workshops3">
              <MKBox mb={1}>
                <DefaultReviewCard
                  color="light"
                  name="Seung W. Choi (University of Texas, Austin) and Wim J. van der Linden (University of Twente)"
                  date="Sept. 24 (Tuesday)"
                  title="The Shadow-Test Approach to Adaptive Testing"
                  review="This short course has four different sections. In the first section, we explain the ideas underlying
              the shadow-test approach, discuss a few practical aspects of its implementation, and show some
              of its generalizations to different test formats. The next two sections present two applications of
              the approach illustrating its versatility. One application is adaptive testing with a mixture of
              discrete items and items organized as sets around a common stimulus with continued
              adaptation within each set. The other is adaptive testing with field-test items inserted for
              adaptive calibration. The final section of the course demonstrates software available for the
              
              implementation of the shadow-test approach to adaptive testing and offers the participants
              hands-on experience with the R package TestDesign. In addition, a brief introduction will be given
              to Optimal CAT, a currently freely downloadable microservice available for easy integration with
              current test delivery systems."
                  // rating={5}
                />
              </MKBox>
            </section>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default KeyNoteTeam;
