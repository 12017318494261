/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MKAlert from "components/MKAlert";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "api/axios";
import OPTInputComponentForSignUp from "components/users/optInputSignup";

function ResetPassBasic() {
  const SEND_EMAIL_URL = "/user/sendEmail";
  const CHECK_EMAIL_EXIST = "/user/checkEmailExists";

  const [rememberMe, setRememberMe] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isMissingField, setIsMissingField] = useState(false);
  const [isTakenEmail, setIsTakenEmail] = useState(false);

  // OTP
  const [otp, setOtp] = useState(Math.floor(Math.random() * 9000 + 1000));
  const resetOTP = async () => {
    const newOtp = Math.floor(Math.random() * 9000 + 1000);
    setOtp(newOtp);
    await sendEmail(newOtp);
  };

  const sendEmail = async (otp) => {
    await axios.post(SEND_EMAIL_URL, {
      email: email,
      subject: "Verify your email",
      message:
        `<body marginheight='0' topmargin='0' marginwidth='0' style='margin: 0px; background-color: #f2f3f8;' leftmargin='0'><!--100% body table--><table cellspacing='0' border='0' cellpadding='0' width='100%' bgcolor='#f2f3f8' style='@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;'><tr><td><table style='background-color: #f2f3f8; max-width:670px;  margin:0 auto;' width='100%' border='0' align='center' cellpadding='0' cellspacing='0'> <tr><td style='height:80px;'>&nbsp;</td></tr><tr><td style='text-align:center;'></td></tr><tr><td style='height:20px;'>&nbsp;</td></tr> <tr> <td><table width='95%' border='0' align='center' cellpadding='0' cellspacing='0' style='max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);'> <tr><td style='height:40px;'>&nbsp;</td></tr><tr><td style='padding:0 35px;'><h1 style='color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;'>IACAT 2024 Sign Up Code</h1> <span style='display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;'></span><p style='color:#455056; text-align: left; font-size:15px;line-height:24px; margin:0;'>Please use the following one time passcode to verify your email.</p><div href='javascript:void(0);' style='background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff; font-size:14px;padding:10px 24px;display:inline-block;'>${otp}</div></td></tr><tr><td style='height:40px;'>&nbsp;</td></tr></table></td><tr><td style='height:20px;'>&nbsp;</td></tr><tr></td></tr></table></td></tr></table></body>`,
    });
  };

  const [verifyEmail, setVerifyEmail] = useState(false);

  const isEmailValid = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const verifyEmailExists = async () => {
    if (email === "") {
      setIsMissingField(true);
    } else {
      try {
        const response = await axios.post(CHECK_EMAIL_EXIST, {
          email: email,
        });
        setVerifyEmail(true);
        sendEmail(otp);
      } catch (err) {
        if (err.response) {
          if (err.response.status) {
            if (err.response.status === 404) {
              setIsTakenEmail(true);
            }
          }
        }
      }
    }
  };

  const [email, setEmail] = useState("");

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Reset your password
                </MKTypography>
              </MKBox>
              {verifyEmail ? (
                <OPTInputComponentForSignUp
                  otpCode={otp}
                  reset={() => resetOTP()}
                  email={email}
                  isReset={true}
                />
              ) : (
                <div>
                  <MKBox component="form" role="form" px={2}>
                    {isMissingField && (
                      <MKAlert
                        color="error"
                        dismissible
                        onClick={() => setIsMissingField(false)}
                      >
                        Missing email or password
                      </MKAlert>
                    )}
                    {!isValidEmail && (
                      <MKAlert
                        color="error"
                        dismissible
                        onClick={() => {}}
                      >
                        Invalid Email
                      </MKAlert>
                    )}
                    {isTakenEmail && (
                      <MKAlert
                        color="secondary"
                        dismissible
                        onClick={() => {}}
                      >
                        This email does not have an account yet. Please sign up. 
                      </MKAlert>
                    )}
                    <MKBox mb={2} mt={2}>
                      <MKInput
                        type="email"
                        label="Known Email"
                        fullWidth
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setIsValidEmail(isEmailValid(e.target.value));
                        }}
                      />
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={verifyEmailExists}
                      >
                        Send one time token
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default ResetPassBasic;
