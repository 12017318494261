/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

import jhr from "assets/images/localcomittee/jhr.png";
import gml from "assets/images/localcomittee/gml.png";
import hjp from "assets/images/localcomittee/hjp.png";
import hjs from "assets/images/localcomittee/hjs.png";
import hss from "assets/images/localcomittee/Hyesung_Shin_profile.jpg";

function Team() {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={8} sx={{ mb: 6 }}>
          <MKTypography variant="h3" color="white">
            Local Organizing Committee
          </MKTypography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={jhr}
              name="Ji Hoon Ryoo"
              position={{ color: "info", label: "Chair" }}
              description="Yonsei University, South Korea"
            />
          </MKBox>
        </Grid>
        <br></br>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={gml}
              name="Guemin Lee"
              position={{ color: "info", label: "" }}
              description="Yonsei University, South Korea"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={hjp}
              name="Hyun-Jeong Park"
              position={{ color: "info", label: "" }}
              description="Seoul National University, South Korea"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={hjs}
              name="Hyojeong Shin"
              position={{ color: "info", label: "" }}
              description="Sogang University, South Korea"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <HorizontalTeamCard
              image={hss}
              name="Hyesung Shin"
              position={{ color: "info", label: "" }}
              description="Yonsei University, South Korea"
            />
          </MKBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Team;
