import axios from 'axios';
// const BASE_URL = 'https://iacat2024api.com';
const BASE_URL = 'http://localhost:8001'

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate =  axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type' : 'application/json'},
});