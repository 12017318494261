/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Board images
import na from "assets/images/board/na.png"
import alina from "assets/images/board/alina.jpeg"
import wim from "assets/images/board/wim.png"
import alper from "assets/images/board/alper.jpeg"
import duanli from "assets/images/board/duanli.jpeg"
import john from "assets/images/board/john.jpeg"
import kathleen from "assets/images/board/kathleen.jpeg"
import nathan from "assets/images/board/Nathan.jpeg"
import Theo from "assets/images/board/Theo.jpeg"
import tony from "assets/images/board/tony.jpeg"


function Board() {
  return (
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
                Organizing Committee / Board of IACAT-Directors
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={tony}
                name="Tony Zara"
                position={{ color: "info", label: "President" }}
                description="Pearson VUE"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={wim}
                name="Wim. van der Linden"
                position={{ color: "info", label: "President - Elect" }}
                description="University of Twente"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={duanli}
                name="Duanli Yan"
                position={{ color: "info", label: "Secretary" }}
                description="ETS"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={nathan}
                name="Nathan Thompson"
                position={{ color: "info", label: "Membership Director" }}
                description="ASC"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
  );
}

export default Board;
