import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";
import iacat from "assets/images/iacatsmall.png";

function HostLogo() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={6} mx="auto">
          <MKBox textAlign="center">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4} lg={4}>
                <Tooltip title="IACAT">
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={iacat}
                      width="100%"
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={4}>
              </Grid>
              <Grid item xs={4} lg={4}>
                <Tooltip title="Yonsei University">
                  <Box
                    opacity={0.5}
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={yonseiEmblem}
                      width="100%"
                    />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HostLogo;
