// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png"

const date = new Date().getFullYear();

const footerConfig = {
  brand: {
    name: "IACAT 2024",
    image: yonseiEmblem,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/adaptivetesting",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/groups?gid=2588587&trk=vsrp_groups_res_name&trkInfo=VSRP",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@ysuniversity",
    },
  ],
  menus: [
    {
      name: "Conference Details",
      items: [
        { name: "Conference Theme", href: "/pages/conferencetheme" },
        { name: "Call for Proposals", href: "/pages/callforpapers" },
        { name: "Awards/Grants", href: "/pages/awardgrants"},
        { name: "Committee", href: "/pages/committee" },
      ],
    },
    {
      name: "Travel",
      items: [
        { name: "General", href: "/pages/travel" },
        { name: "L7 Hotel", href: "/travel/l7hotel" },
        { name: "Mercure Ambassador Hotel", href: "/travel/mercureambassadorhotel"},
        { name: "Airport Shuttle", href: "/travel/bus" },
        { name: "Subway", href: "/travel/subway" },
        { name: "Taxi", href: "/travel/taxi" },
      ],
    },
    {
      name: "Events",
      items: [
        { name: "Keynotes", href: "/pages/keynotes" },
        { name: "Social Event Registration", href:"/user/register/events/checkout"}
        // { name: "Day 1", href: "/" },
        // { name: "Day 2", href: "/" },
      ],
    },
    // {
    //   name: "Social",
    //   items: [
    //     { name: "Conference Dinner", href: "/" },
    //     { name: "Leisure", href: "/" },
    //     { name: "Networking", href: "/" },
    //   ],
    // }
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} IACAT <br />
      <small className="text-left">
      CLASS-AnalyticeInc. <br/>
      CEO : Ryoo Ji Hoon<br/>
      Business Number : 402-87-03067<br/>
      Mail-order-sales registration number :<br/>
      Adress: 50, Yonsei-ro2-gil, Seodaemun-gu, Seoul, Republic of Korea<br/>
      Tel : 02-2123-3174<br/>
      e-mail : ryoox001@yonsei.ac.kr<br/>
      </small>
    </MKTypography>
  ),
};

export default footerConfig;

