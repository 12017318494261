/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

function ConferenceHeader() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={yonseiEmblem} alt="Yonsei" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <MKTypography variant="h2">Conference Theme</MKTypography>
              </MKBox>
              <MKTypography variant="h3" fontWeight="light" color="text">
                Improving Assessment with Adaptivity and Artificial Intelligence
              </MKTypography>
              <MKTypography
                variant="body1"
                fontWeight="light"
                color="text"
                mt={2}
              >
                The conference theme centers on the application of adaptivity,
                artificial intelligence (AI), and other algorithms to improve
                assessment development, delivery, and implementation. Why? AI is
                the use of computers to do things that normally require a
                human. Before the advent of computers, trained psychologists
                delivered adaptive tests, and therefore computerized adaptive
                testing (CAT) was one of the first implementations of
                AI. Automated test assembly, automated essay scoring, automated
                item generation, and online item calibration are other examples
                of algorithms to improve assessment. But AI is also useful in
                the implementation of assessments and the interpretation of
                assessment data. Learn more about the conference at
                the conference website.{" "}
              </MKTypography>
              <MKTypography variant="h2" mt={4}>
                Strands
              </MKTypography>

              <MKTypography
                variant="body1"
                fontWeight="light"
                color="text"
                mt={2}
              >
                <ol>
                  <li>
                    Automatic item generation with machine learning algorithms
                  </li>
                  <li>Automatic scoring in CAT with AI models</li>
                  <li>Bayesian approaches to CAT</li>
                  <li>CAT applications</li>
                  <li>CAT in large-scale assessments</li>
                  <li>Cognitive diagnosis CAT</li>
                  <li>
                    Constraint Management in CAT (e.g., content balancing,
                    exposure control)
                  </li>
                  <li>Detection of disengagement and cheating with AI</li>
                  <li>Formative adaptive assessment</li>
                  <li>Item banking</li>
                  <li>Item pool design</li>
                  <li>Item selection methods</li>
                  <li>(M)IRT models in CAT</li>
                  <li>Multi-stage testing</li>
                  <li>Natural Language Processing in CAT</li>
                  <li>Online-calibration</li>
                  <li>Usage of AI to score oral responses</li>
                  <li>Software for CAT development & application</li>
                  <li>Statistical foundations of CAT</li>
                  <li>
                    Systems and models connecting learning and adaptive testing
                  </li>
                </ol>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ConferenceHeader;
