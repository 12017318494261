/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MKAlert from "components/MKAlert";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";
import MKBadge from "components/MKBadge";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "api/axios";
import { jwtDecode } from "jwt-decode";

import Icon from "@mui/material/Icon";

function UserPageBasic() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(() => {
    if (storedToken) {
      return jwtDecode(storedToken);
    }
    return {};
  });
  const [fname, setFname] = useState(userInfo.fname || "");
  const [lname, setLname] = useState(userInfo.lname || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMissingField, setIsMissingField] = useState(false);
  useEffect(() => {
    if (storedToken == null) {
      navigate("/signin", { replace: true });
    }
  }, [storedToken, navigate]);

  useEffect(() => {
    if (loggedin === null || loggedin === false) {
      navigate("/signin", { replace: true });
    }
  }, [loggedin, navigate]);
  const [paid,setPaid] = useState(userInfo.paid);

  const [email] = useState(userInfo.email);
  const [role, setRole] = useState(userInfo.role);


  const logout = async () => {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("EXPIRES_IN");
    localStorage.removeItem("STATUS");
    navigate("/", { replace: true });
  };

  const updateUserInfo = async () => {
    setIsMissingField(false);
    setIsUpdating(true);
    if (email === "" || fname === "" || lname === "") {
      setIsMissingField(true);
      setIsUpdating(false);
    } else {
      const response = await axios.post(
        "/user/updateUserInfo",
        {
          email: email,
          fname: fname,
          lname: lname,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      localStorage.setItem("ACCESS_TOKEN", response.data.access_token);
      localStorage.setItem("EXPIRES_IN", response.data.expires_in.toString());
      localStorage.setItem("STATUS", "true");
      setIsUpdating(false);
      refreshUserInfo();
    }
  };
  const refreshUserInfo = async () => {
    setIsUpdating(true);
    if (email === "" || fname === "" || lname === "") {
      setIsMissingField(true);
      setIsUpdating(false);
    } else {
      const response = await axios.post(
        "/user/getUserInfo",
        {
          email: email
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      localStorage.setItem("ACCESS_TOKEN", response.data.access_token);
      localStorage.setItem("EXPIRES_IN", response.data.expires_in.toString());
      localStorage.setItem("STATUS", "true");
      let decodedUserInfo = jwtDecode(response.data.access_token)
      setUserInfo(decodedUserInfo);
      setFname(decodedUserInfo.fname);
      setLname(decodedUserInfo.lname);
      setRole(decodedUserInfo.role);
      setPaid(decodedUserInfo.paid)
      setIsUpdating(false);
    }
  };

  return (
    <>
      <DefaultNavbar
        // action={{
        //   type: "internal",
        //   route: loggedin ? "/userinfo" : "/signin",
        //   label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
        //   color: "info",
        // }}
        routes={routes}
        transparent
        light
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  User Info
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {isUpdating ? (
                  <div className="flex justify-center items-center h-40">
                    <div
                      className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                      role="status"
                      aria-label="loading"
                    >
                      <span className="sr-only">{"Loading"}...</span>
                    </div>
                  </div>
                ) : (
                  <MKBox component="form" role="form">
                    {isMissingField && (
                      <MKAlert
                        color="error"
                        dismissible
                        onClick={() => setIsMissingField(false)}
                      >
                        First name and Last name cannot be empty
                      </MKAlert>
                    )}
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        Email
                      </MKTypography>
                      <MKInput type="email" fullWidth value={email} disabled />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        Role
                      </MKTypography>
                      <MKInput type="text" fullWidth value={role} disabled />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        First Name
                      </MKTypography>
                      <MKInput
                        type="text"
                        fullWidth
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        Last Name
                      </MKTypography>
                      <MKInput
                        type="text"
                        fullWidth
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        Membership Status{" "}
                        {paid ? (
                          <MKBadge
                            badgeContent="Registered"
                            size="xs"
                            container
                          />
                        ) : (
                          <a href="/user/registration" target="_blank">
                          <MKBadge
                            badgeContent="Not Registered"
                            size="xs"
                            container
                            color="error"
                          /></a>
                        )}
                        </MKTypography>
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography fontWeight="light" variant="h6">
                        Refresh UserInfo{" "}
                        <MKButton variant="gradient" color="warning" iconOnly size="small" onClick={refreshUserInfo}>
                          <Icon sx={{ fontWeight: "bold" }}>replay_icon</Icon>
                        </MKButton>
                        </MKTypography>
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={updateUserInfo}
                      >
                        update info
                      </MKButton>
                    </MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        variant="gradient"
                        color="error"
                        fullWidth
                        onClick={logout}
                      >
                        logout
                      </MKButton>
                    </MKBox>
                  </MKBox>
                )}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default UserPageBasic;
