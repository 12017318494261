/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";
import TravelInformation from "./Information";
import HotelInformation from "./HotelInformation";

function TravelHeader() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Grid container item xs={12} justifyContent="center" mx="auto">
        <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          <MKAvatar src={yonseiEmblem} alt="Yonsei" size="xxl" shadow="xl" />
        </MKBox>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid container justifyContent="center" py={3}>
            <Grid item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MKTypography variant="h3">Travel</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                Below you can find VISA, hotel accommodations, and
                transportation options.
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <MKTypography variant="h5">VISA Information</MKTypography>
              </MKBox>
              <MKTypography variant="body1" color="text" mb={1}>
                If your travel to South Korea requires a VISA, please visit (
                <a
                  href="https://www.k-eta.go.kr/portal/apply/index.do?locale=EN"
                  style={{ color: "blue" }}
                >
                  Official Korea Electronic Travel Authorization Website, Korea
                  Immigration Service &lt; K-ETA
                </a>
                )
                <br />
                <strong>
                  <small>
                    * If a tourist visa is not issued on the website, please
                    refer to the website of the embassy or consulate of the
                    respective country.
                  </small>
                </strong>{" "}
                <br />
                <strong>
                  <small>
                    ** To obtain a visa, if you require a support letter, please
                    contact us at
                    <a href="mailto:7castle42@gmail.com">
                      (7castle42@gmail.com)
                    </a>{" "}
                    or
                    <a href="mailto:ryoox001@yonsei.ac.kr">
                      (ryoox001@yonsei.ac.kr)
                    </a>
                    , and we will provide the necessary documentation.
                  </small>
                </strong>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={3}>
          <Grid item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <MKTypography variant="h5">Hotel Accommodation</MKTypography>
            </MKBox>
            <MKTypography variant="body1" fontWeight="light" color="text">
              Special room rates have been arranged with a number of hotels of
              different categories for the 2024 IACAT Conference. And Yonsei
              University is about a 10-minute drive from the hotel, and a
              shuttle bus is provided. Please click on each card to view
              specifics to each hotel.
            </MKTypography>
          </Grid>
        </Grid>
      </Grid>
      <HotelInformation />
      <Grid container justifyContent="center">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={5}
            >
              <MKTypography variant="h5">Transportation Options</MKTypography>
            </MKBox>
            <MKTypography variant="body1" fontWeight="light" color="text">
              You can fly to Incheon International Airport (ICN). Form Incheon
              airport, you can reach the Yonsei University by airport bus,
              subway and taxi.
            </MKTypography>
            <MKTypography
              variant="body1"
              fontWeight="light"
              color="text"
              mt={2}
            >
              Please click on each card to get more specific instructions
            </MKTypography>
            <MKTypography
              variant="body2"
              fontWeight="light"
              color="text"
              mt={2}
            >
              Note that we recommend using Google Maps and the{" "}
              <a href="https://www.yonsei.ac.kr/en_sc/intro/directions1.jsp">
                University website
              </a>{" "}
              for searching more various public transport connections and
              details.
            </MKTypography>
          </Grid>
        </Grid>
      </Grid>
      <TravelInformation />
    </MKBox>
  );
}

export default TravelHeader;
