import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import HostLogo from "./HostLogo";

const containerStyle = {
  position: "relative",
  width: "100%",
  paddingBottom: "56.25%", // 16:9 aspect ratio
  height: 0,
  overflow: "hidden",
};

const iframeStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

function AboutBlock() {
  return (
    <MKBox component="section" my={2} py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={0}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          {/* <MKTypography variant="h2">Trusted by over</MKTypography> */}
          <Grid
            item
            xs={12}
            lg={12}
            container
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", mb: { xs: 2, lg: 0 } }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Venue"
              container
              sx={{ mb: 2 }}
            />
          </Grid>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Join us at the 2024 IACAT conference!
          </MKTypography>
          <HostLogo />
          <MKTypography variant="body1" color="text" mb={2} textAlign={"left"}>
            We are delighted to announce that the 9th IACAT conference will be
            hosted by the Department of Education of Yonsei University, Seoul,
            Korea. Yonsei University was founded in 1885 by visionaries who
            recognized the value of education that transcends racial and
            national boundaries. Over the past 139 years, approximately 370,000
            people have earned a degree at Yonsei University, contributing to
            the development of society in the spirit of truth and freedom. Even
            now, as a new period in our globally-shared civilization begins,
            Yonsei University is widely recognized as a world-class University
            and Yonseians shine brightly in the unique ways. Several research
            groups at the University and associated research institutes are
            devoted to research on educational measurement and psychometrics.
            <br /> <br />
            The conference will take place on The Lounge at Main Street (called
            Baekyang-ro) Campus of Yonsei University, a place of innovation that
            upholds the tradition and heritage of the Yonsei Spirit. In addition
            to keynotes given by several of the most distinguished researchers
            in the field, there will be a wealth of individual oral
            presentations, symposia, and innovative interactive sessions on
            cutting-edge CAT research and applications. A number of awards and
            student grants will be available{" "}
            <a href="/pages/awardgrants">here</a>.
            <br /> <br />
            The campus is easily accessible from the airport via airport bus,
            subway, or taxi. Yonsei University was founded in 1885 by
            visionaries and over the past 139 years, approximately 370,000
            people have earned a degree at Yonsei University, contributing to
            the development of society in the spirit of truth and freedom.
            Yonsei University is widely recognized as a world-class University
            and Yonseians shine brightly in the unique ways. Several research
            groups at the University and associated research institutes are
            devoted to research on educational measurement (psychometrics) and
            educational statistics.
            <Grid
              container
              item
              mt={2}
              xs={12}
              lg={0}
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <div style={containerStyle}>
                <iframe
                  src="https://ncms.yonsei.ac.kr/contents2/yonseil001/61cba3cd35ce5/contents/media_files/mobile/ssmovie.mp4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  controls
                  style={iframeStyle}
                ></iframe>
              </div>
            </Grid>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutBlock;
