/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";

function Awards() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  return (
    <>
      <DefaultNavbar
        // action={{
        //   type: "internal",
        //   route: loggedin ? "/userinfo" : "/signin",
        //   label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
        //   color: "info",
        // }}
        routes={routes}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={6}>
                  <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h1">
                        Researcher Grants and Awards
                      </MKTypography>
                    </MKBox>
                    <MKTypography variant="body1" color="text">
                      {" "}
                      In order to encourage further research and advancement in
                      Computerized Adaptive Testing (CAT), IACAT has established
                      several Research Awards and Grants.  The following three
                      are relevant for the IACAT 2024 Conference:
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2}>
                      <div style={{ paddingLeft: "50px" }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "20px",
                            margin: 0, // Ensure there's no margin affecting the list
                          }}
                        >
                          <li style={{ paddingLeft: "10px" }}>
                            Early Career Researcher Award
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            Matthew Finkelman Graduate Student Research Award
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            Student Presentation Travel Grants
                          </li>
                        </ul>
                      </div>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2}>
                      {" "}
                      The purpose of these awards is to encourage younger
                      professionals to contribute to research in CAT and to
                      participate in the IACAT conferences. <br />
                      <br />
                      <strong>Application Deadline: June 30, 2024</strong>
                    </MKTypography>
                    <MKTypography variant="h3" mt={5}>
                      1. Early Career Researcher Award
                    </MKTypography>
                    <MKTypography variant="h6" mt={1}>
                      Application Deadline:June 30, 2024
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      The IACAT Early Career Researcher Award is a prestigious
                      award intended to support early career scholars with an
                      interest in CAT. The recipient of this award will receive
                      a trophy/medal from the sponsor,{" "}
                      <u>
                        <a
                          href="https://www.epecat.com/"
                          style={{ color: "blue" }}
                        >
                          EPEC Pty Ltd
                        </a>
                      </u>
                      , a grant from IACAT of up to USD$2500 so that they can
                      attend the conference at which their award is to be
                      presented. They will be invited to present on their
                      research topic related to CAT during a{" "}
                      <strong>
                        <i>Plenary Session</i>
                      </strong>{" "}
                      at the upcoming IACAT Conference. It is expected that the
                      recipient submit an article based on the presentation to
                      IACAT's <i>Journal of Computerized Adaptive Testing</i>{" "}
                      for peer review and possible publication.
                    </MKTypography>
                    <MKTypography variant="h4" mt={1}>
                      Eligibility Criteria
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      To be eligible for this award, the applicant should;
                      <div style={{ paddingLeft: "50px" }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "20px",
                            margin: 0, // Ensure there's no margin affecting the list
                          }}
                        >
                          {" "}
                          <li>
                            Have obtained at least a Master's degree (or be at
                            an advanced stage in completing a Master's degree)
                            in the field of measurement within the last 10
                            years.
                          </li>
                          <li>
                            Be nominated by a Graduate Faculty Member or
                            Supervisor/Manager for their contribution to the
                            field
                          </li>
                          <li>
                            Be willing and able to present at the upcoming IACAT
                            Conference
                          </li>
                        </ul>
                      </div>
                    </MKTypography>
                    <MKTypography variant="h4" mt={1}>
                      Application Procedure
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      In order to apply for consideration to receive the award,
                      the following needs to be submitted to Prof. John Barnard{" "}
                      <a
                        href="mailto:johnbarnard@bigpond.com"
                        style={{ color: "blue" }}
                      >
                        (johnbarnard@bigpond.com)
                      </a>{" "}
                      by June 30, 2024:
                      <div style={{ paddingLeft: "50px" }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "20px",
                            margin: 0, // Ensure there's no margin affecting the list
                          }}
                        >
                          {" "}
                          <li>
                            An abstract of up to 500 words on a topic in the
                            field of CAT
                          </li>
                          <li>
                            A letter of recommendation from the nominator and
                            the nominator's contact details
                          </li>
                          <li>
                            A detailed curriculum vitae of the applicant with
                            special reference to CAT
                          </li>
                        </ul>
                      </div>
                      The recipient of the Award will be notified by{" "}
                      <u>July 10, 2024 </u>, and will need to confirm acceptance
                      within 10 days after notification.
                    </MKTypography>
                    <MKTypography variant="h4" mt={1}>
                      Previous Recipients
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      <div style={{ paddingLeft: "50px" }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "20px",
                            margin: 0, // Ensure there's no margin affecting the list
                          }}
                        >
                          <li style={{ paddingLeft: "10px" }}>
                            2014 recipient - Dr Chun Wang from the{" "}
                            <a
                              href="https://twin-cities.umn.edu/"
                              style={{ color: "blue" }}
                            >
                              University of Minnesota
                            </a>
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            2015 recipient - Dr Duanli Yan from the{" "}
                            <a
                              href="https://www.ets.org/"
                              style={{ color: "blue" }}
                            >
                              Educational Testing Service
                            </a>
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            2017 recipient - Dr Maaike van Groen from{" "}
                            <a
                              href="https://www.ets.org/"
                              style={{ color: "blue" }}
                            >
                              Cito
                            </a>
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            2019 recipient - Dr Shiyu Wang from the University
                            of Georgia
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            2022 recipient - Dr Miguel A Sorrel from the
                            University of Madrid
                          </li>
                        </ul>
                      </div>
                    </MKTypography>
                    <MKTypography variant="h3" mt={10}>
                      2. Matthew Finkelman Graduate Student Research Award
                    </MKTypography>
                    <MKTypography variant="h6" mt={1}>
                      Application Deadline: June 30, 2024
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      To be awarded based on a research proposal submitted by
                      currently enrolled graduate students for a research
                      project that addresses a significant CAT research issue.
                      The award will be based on its potential for the proposed
                      research to provide useful information to enhance the
                      implementation or improvement of CAT in a potential
                      application of CAT methods. The recipient will receive
                      US$500 (courtesy of Professor Finkelman), an all-expense
                      paid trip to the conference in Korea, a certificate of
                      award, the opportunity to present their proposal in a
                      plenary session of the conference, and an invitation to
                      submit their completed research to IACAT's{" "}
                      <i>Journal of Computerized Adaptive Testing</i> for peer
                      review.
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      <div style={{ paddingLeft: "50px" }}>
                        <ol
                          style={{
                            listStyleType: "decimal",
                            paddingLeft: "20px",
                            margin: 0,
                          }}
                        >
                          <li style={{ paddingLeft: "10px" }}>
                            A statement of the problem.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            Its significance for improving an important CAT
                            application.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            A review of prior research.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            The research design as a Method section for the
                            research, including proposed analyses, expected
                            outcomes, and implications for CAT implementation.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            The proposal should be submitted as an electronic
                            file in either Word or PDF format, limited to four
                            pages, single-spaced, which would be about 2,000
                            words. The author's name, affiliation, and contact
                            information should appear on the first page (this
                            information will be removed from the file).
                            Proposals will be independently reviewed by three
                            IACAT senior members.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            Include with your proposal a copy of your student ID
                            card or a letter from your adviser confirming your
                            active graduate student status.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            Proposals will be accepted until June 30, 2024.
                            Submit your proposal by email to Prof. John Barnard{" "}
                            <a
                              href="mailto:johnbarnard@bigpond.com"
                              style={{ color: "blue" }}
                            >
                              (johnbarnard@bigpond.com)
                            </a>{" "}
                            by the deadline.
                          </li>
                          <li style={{ paddingLeft: "10px" }}>
                            If a single award is made it will be announced no
                            later than <u>July 15, 2024</u>.
                          </li>
                        </ol>
                      </div>
                    </MKTypography>
                    <MKTypography variant="h3" mt={10}>
                      3. Student Presentation Travel Grants
                    </MKTypography>
                    <MKTypography variant="h6" mt={1}>
                      Application Deadline: June 30, 2024
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      Students (PhD or Master study programmes) who wish to
                      present a paper at the IACAT conference can apply for a
                      student presentation grant. IACAT has made available up to
                      ten grants (5 x USD$1000 for students from outside of
                      South Korea; 5 x USD$500 for students from South Korea) to
                      assist with travel and accommodation expenses for such
                      students to attend the upcoming conference. The recipients
                      of the grants will be invited to present their papers at
                      the upcoming IACAT Conference and to submit a manuscript
                      on their research to the Journal of Computerized Adaptive
                      Testing. You must be a graduate student to apply.
                    </MKTypography>
                    <MKTypography variant="h4" mt={1}>
                      Application Procedure
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      In order to apply for consideration to receive a grant,
                      the following needs to be submitted to Prof. John Barnard{" "}
                      <a
                        href="mailto:johnbarnard@bigpond.com"
                        style={{ color: "blue" }}
                      >
                        (johnbarnard@bigpond.com)
                      </a>{" "}
                      by June 30, 2024:
                      <div style={{ paddingLeft: "50px" }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "20px",
                            margin: 0, // Ensure there's no margin affecting the list
                          }}
                        >
                          {" "}
                          <li>
                            An abstract of up to 500 words that you submitted
                            for the conference and that was accepted
                          </li>
                          <li>
                            Evidence such as a student identification card
                            indicating you are currently a student
                          </li>
                          <li>
                            The name and contact details of the principal
                            Advisor or Supervisor where the applicant is
                            undertaking their studies
                          </li>
                        </ul>
                      </div>
                      An outstanding application may be considered for the
                      prestigious Early Career Researcher Award.
                      <br />
                      <br />
                      The recipient of the Award will be notified by{" "}
                      <u>July 15, 2024 </u>.
                    </MKTypography>
                    <br />
                    <br />
                    <small>
                      <i>
                        The number of awards to be made in any time period is
                        solely at the discretion of the IACAT Board of
                        Directors, and IACAT reserves the right to not make any
                        awards during any time period.
                      </i>
                    </small>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default Awards;
