/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";

import Breadcrumbs from "examples/Breadcrumbs";

function BusInstructions() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={0}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <Breadcrumbs
                      routes={[
                        { label: "Home", route: "/home" },
                        {
                          label: "Travel",
                          route: "/pages/travel",
                        },
                        { label: "Bus Travel" },
                      ]}
                    />
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      py={2}
                    >
                      <MKTypography variant="h3">
                        Bus Travel information
                      </MKTypography>
                    </MKBox>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      To travel from Incheon Airport to Yonsei University by
                      airport bus, you can follow these steps:
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      <ol>
                        <li>
                          Incheon International Airport Terminal 1 or 2:
                          Depending on the terminal you arrive at, you can
                          access airport buses from either Terminal 1 or
                          Terminal 2.
                        </li>
                        <li>
                          Purchase Bus Ticket: Head to the airport bus ticket
                          counter or kiosk at the terminal and purchase a ticket
                          for an airport bus that goes to Sinchon or Sinchon
                          Rotary (신촌로터리). You may see buses with numbers
                          like 6002 or 6011, which are popular options for
                          getting to Sinchon.
                        </li>
                        <li>
                          Board the Bus: After obtaining your ticket, proceed to
                          the designated bus stop or platform outside the
                          airport terminal where your chosen bus departs.
                        </li>
                        <li>
                          Ride to Sinchon: The airport bus will take you to
                          Sinchon. The journey may take around 1 to 1.5 hours,
                          depending on traffic conditions.
                        </li>
                        <li>
                          Get off at Sinchon: Once you arrive at Sinchon, you
                          can disembark from the bus. Sinchon is the nearest
                          major area to Yonsei University.
                        </li>
                        <li>
                          Walking to Yonsei University: From Sinchon, Yonsei
                          University's campus is within walking distance. You
                          can follow signs or use a map app to navigate to the
                          University, which should be approximately a 10-15
                          minute walk.
                        </li>
                      </ol>
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default BusInstructions;
