/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ChangeEvent, useRef, useState } from "react";
import MKAlert from "components/MKAlert";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";
import { jwtDecode } from "jwt-decode";
import axios from "api/axios";

function ProposalSubmissions() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  const [isUploading, setIsUploading] = useState(false);
  const [isMissingField, setIsMissingField] = useState(false);
  const [fname] = useState(userInfo ? userInfo.fname : null);
  const [lname] = useState(userInfo ? userInfo.lname : null);
  const [email] = useState(userInfo ? userInfo.email : null);
  const [institution, setInstitution] = useState("");

  const [file, setFile] = useState();
  const [finishedSubmission, setFinishedSubmission] = useState(false);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  async function uploadSubmission(event) {
    setIsMissingField(false);
    if (file && institution !== "") {
      setIsUploading(true);
      event.preventDefault();
      const url = "/user/submitProposal";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("email", email);
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("institution", institution);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await axios.post(url, formData, config);
      setIsUploading(false);
      setFinishedSubmission(true);
    } else {
      setIsMissingField(true);
    }
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        brand={"IACAT 2024"}
        sticky
        // action={{
        //   type: "internal",
        //   route: loggedin ? "/userinfo" : "/signin",
        //   label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
        //   color: "info",
        // }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={6}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h2">
                        Proposal Submission
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="h6">
                        Deadline: <u>July 6, 2024</u>
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      <MKTypography variant="caption">
                        * Please note: The deadline for proposals have been extended to July 6, 2024
                      </MKTypography>
                    </MKBox>
                    <Grid spacing={1} mt={4} justifyContent="center">
                      <Card>
                        <MKBox
                          px={2}
                          color="white"
                          variant="gradient"
                          borderRadius="lg"
                          shadow="lg"
                        >
                          {loggedin ? (
                            isUploading ? (
                              <div className="flex justify-center items-center h-40">
                                <div
                                  className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                                  role="status"
                                  aria-label="loading"
                                >
                                  <span className="sr-only">
                                    {"Loading"}...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <MKBox component="form" role="form">
                                {isMissingField && (
                                  <MKAlert
                                    color="error"
                                    dismissible
                                    onClick={() => setIsMissingField(false)}
                                  >
                                    You must submit a PDF and Institution must
                                    not be blank.
                                  </MKAlert>
                                )}
                                {finishedSubmission ? (
                                  <div>
                                    Thank you for your submission. Please check
                                    your email inbox for a confirmation. If you
                                    did not recieve an email, please contact
                                    support@iacat2024.com
                                  </div>
                                ) : (
                                  <>
                                    <MKBox mb={2}>
                                      <MKTypography
                                        fontWeight="light"
                                        variant="h6"
                                      >
                                        Email
                                      </MKTypography>
                                      <MKInput
                                        type="email"
                                        fullWidth
                                        value={email}
                                        disabled
                                      />
                                    </MKBox>
                                    <MKBox mb={2}>
                                      <MKTypography
                                        fontWeight="light"
                                        variant="h6"
                                      >
                                        Institution
                                      </MKTypography>
                                      <MKInput
                                        type="Institution"
                                        fullWidth
                                        color="light"
                                        value={institution}
                                        onChange={(e) =>
                                          setInstitution(e.target.value)
                                        }
                                      />
                                    </MKBox>
                                    <MKBox mb={2}>
                                      <MKTypography
                                        fontWeight="light"
                                        variant="h6"
                                      >
                                        Upload PDF
                                      </MKTypography>

                                      <input
                                        type="file"
                                        onChange={handleChange}
                                      />
                                    </MKBox>
                                    <MKBox mt={4} mb={1}>
                                      <MKButton
                                        variant="gradient"
                                        color="dark"
                                        fullWidth
                                        onClick={uploadSubmission}
                                      >
                                        Submit
                                      </MKButton>
                                    </MKBox>
                                  </>
                                )}
                              </MKBox>
                            )
                          ) : (
                            <MKTypography variant="h6">
                              You must log in first to submit a proposal.
                            </MKTypography>
                          )}
                        </MKBox>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default ProposalSubmissions;
