/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
// import Contact from "pages/LandingPages/Author/sections/Contact";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import ConferenceHeader from "pages/LandingPages/ConferenceTheme/sections/ConferenceHeader";
import footerRoutes from "footer.routes";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Material Kit 2 React components
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

//pics
import kthan from "assets/images/keynotes/kthan.png";
import mariacrui from "assets/images/keynotes/marianacrui.png";
import peterrijin from "assets/images/keynotes/peterrijin.png";
import swchoi from "assets/images/keynotes/swchoi.png";
import jihoon from "assets/images/localcomittee/jhr.png";
import wim from "assets/images/board/wim.png";
import chia from "assets/images/keynotes/Chia_Ling_Hsu.jpg";

function IndividualKeyNoteTeam() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  const { speaker } = useParams();
  const navigate = useNavigate();
  const data = {
    seungwchoi: {
      title:
        "Optimal test design approach to through-year computerized adaptive testing",
      img: swchoi,
      name: "Seung W. Choi",
      affiliation: "The University of Texas at Austin",
      abstract: `This presentation outlines the principles of optimal test design for creating a through-year
        assessment system. This system involves administering multiple tests at various time points
        during the school year. The goal is to establish a unified assessment system that delivers
        multiple tests with distinct objectives and specifications for instructional guidance and annual
        summative proficiency determination. The system illustrated is a hybrid interim-summative
        assessment involving multiple interim adaptive tests with different objectives and blueprint
        specifications. It provides both within-year instructional feedback and end-of-year summative
        proficiency assessments. Each test comprises multiple segments or phases, assembled
        adaptively using specific optimality criteria and distinct test specifications implemented as a
        mathematical optimization problem. The key design features address the need to use various
        optimization criteria, meet complex test specifications within and between tests, provide
        instructional feedback that aligns with test specifications, track individual examinees with
        customized test initialization, control item exposure for each examinee across test
        administrations, and transition item pools within and between test administrations when
        necessary. The system will be illustrated using an open-source R platform.`,
    },
    kyungthan: {
      title:
        "Enhancing Test-Taking Experience: Innovative CAT Designs for Improved Learning and Evaluation",
      img: kthan,
      name: "K. Chris Han",
      affiliation: "Graduate Management Admission Council®, USA",
      abstract: `Computerized adaptive testing (CAT) has become a cornerstone of modern assessment
        in various educational and professional settings. However, traditional CAT designs have
        primarily focused on measurement efficiency, often overlooking the test taker&#39;s
        experience. This keynote presentation explores recent survey research findings that
        shed light on test takers&#39; preferences and experiences. Drawing from these insights, the
        speaker introduces innovative CAT designs aimed at enhancing the test-taking
        experience. One proposed design focuses on reducing test anxiety by providing test
        takers with more control and flexibility. This includes features such as the ability to
        review and edit responses, ultimately promoting a more relaxed testing environment.
        Another design aims to foster a sense of success and improvement by strategically
        administering items of varying difficulty levels. This approach encourages continued
        learning efforts while maintaining the efficiency of measurement. By incorporating these
        innovative designs, CAT can evolve beyond its traditional role as a mere evaluation tool
        and become a valuable part of the learning process. This presentation highlights the
        importance of considering the test taker&#39;s perspective in CAT design and suggests
        practical strategies for improving the overall test-taking experience.`,
    },
    peterrijin: {
      title:
        "The Impact of Adaptive Testing Designs on Statistical and AI-Based Methods",
      img: peterrijin,
      name: "Peter van Rijn",
      affiliation: "ETS Global",
      abstract: `Adaptive testing designs generate incomplete data that is different from the incomplete
        data produced by non-adaptive testing designs. According to Rubin’s typology, data
        from adaptive tests can be classified as observed at random, but the missing data
        cannot be considered missing completely at random (Mislevy &amp; Chang, 2000). The
        implications of this distinction are significant for statistical and AI-based methods
        applied to adaptive test data but are sometimes overlooked. In addition, certain often-
        used constraints in adaptive testing applications can lead to violations of basic
        assumptions (e.g., independent observations). In this presentation, I will highlight
        specific instances where statistical and AI-based methods can fail for adaptive test data
        and what the consequences of such failures can turn out to be. I will also discuss
        instances where they can be successful following appropriate adjustments. The
        examples encompass item calibration, the evaluation of (item response theory) model
        fit, exposure control, and AI-based scoring of constructed responses. The aim is to
        clarify the nuanced challenges in handling adaptive test data, and to show a path
        forward in terms of research to be done.`,
    },
    jihoonryoo: {
      title: "AI-based AIG within a Learning Engineering Framework",
      img: jihoon,
      name: "Ji Hoon Ryoo",
      affiliation: "Yonsei University",
      abstract: `In recent years, the landscape of educational assessment has seen a transformative
        shift with the rise of Generative AI in Automated Item Generation (AIG). Generative AI
        promises to streamline and improve AIG by creating questions that challenge learners
        cognitively while demonstrating a nuanced understanding of the subject matter. This accessibility
        of generative AI has increased significantly, due to advancements in cloud computing, open-
        source frameworks, and user-friendly interfaces. This also leverages generative models for
        crafting AIG questions tailored to specific learning objectives. However, it does not mean that all
        of subjects would be automated with AIG. Item models of AIG have not been completely
        generated by generative AI, especially within STEM area. This study elaborates an algorithm of
        AI-based AIG incorporated with human validation process well-fitted to mathematics and critical
        thinking items and examines the efficiency of item model generation via the algorithm. In
        addition to the algorithm elaboration with human validation and the examination of the
        efficiency of finalizing item model generation, we also articulate the process by introducing a
        system, called CLASS-Analytics, integrating LLM capabilities, particularly the Retrieval-
        Augmented Generation (RAG) model within a learning engineering framework. CLASS-
        Analytics autonomously validates the question for syntax errors and, upon educator approval,
        auto-reports the question's difficulty and educational utility within the system interface. This
        meticulously orchestrates workflow harnesses the potential of advanced generative models and
        integrates seamlessly with CLASS-Analytics, revolutionizing AIG question creation. Integrating
        generative AI into AIG aligns with strategic goals aimed at enhancing accessibility, efficiency,
        and innovation in educational assessment.`,
    },
    marianacuri: {
      title: "Estimation of MIRT model parameters with deep learning",
      img: mariacrui,
      name: "Mariana Curi",
      affiliation: "University of São Paulo",
      abstract: `In a computerized adaptive testing scenario, practical challenges, such as the
        calibration of item pools, arise when the number of latent variables of interest is large.
        Traditional estimation methods for Item Response Theory (IRT) model parameters are
        either computationally inefficient or incapable of generating estimates for a high-
        dimensional space. <newLine> <newLine>
        Alternatives based on machine learning algorithms are proposed in the literature. <newLine> <newLine>
        This study introduces a deep learning approach to solve the problem of estimating item
        and individual parameters in a high-dimensional space from observed data, which may
        be complete or in the presence of missing data. The performance of the method is
        evaluated in a simulated dataset.`,
    },
    chialinghsu: {
      title: "Variable-length adaptive multistage testing",
      img: chia,
      name: "Chia-Ling Hsu",
      affiliation: "Hong Kong Examinations and Assessment Authority",
      abstract: `Adaptive multistage testing (MST) incorporates most of the benefits of computerized adaptive
        testing's (CAT) while minimizing its drawbacks. Whereas CAT administers one item at a time, MST
        administers a group of items (module). Like CAT, MST adaptively assigning each test-taker to one of
        several preassembled item groups based on his or her performance on previously administered
        items. Also, both CAT and MST retain measurement advantages that enable efficient and precise
        measurement across test-takers' proficiency levels. MST increases test flexibility by allowing test-
        takers to review their item responses within each module, whereas CAT does not. In addition, MST
        improves test efficiency since it can be designed and built before to test administration, resulting in
        greater control over the content balance, quality of the test structure, and test administration as
        compared to CAT. <newLine> <newLine>
        However, existing MST research studies established with the fixed-length termination rule improve
        test administration efficiency but do not assure measurement precision for all test-takers. This is
        especially critical for achievement testing or clinical diagnostic assessment, which require
        measurement precision over a variety of test-takers' proficiency levels. To address this research gap
        in this study, the fixed- precision termination rule (so-called variable-length termination rule) is
        employed to construct MST that enables greater measurement accuracy of the test-takers'
        proficiency levels to achieve a pre-specified measurement precision. In CAT, two commonly-used
        fixed-precision termination criteria are: the maximum standard error (SE) rule (or the minimum
        information rule) and absolute change in theta (CT) rule (Babcock &amp; Weiss, 2012). Based on the
        simulation results, this study presents the analytic results that demonstrate the relationships
        between different fixed-precision termination rules and measurement precision in MST. Specifically,
        when both SE and CT rules are used, the SE rule should be the dominant criterion for stopping MST.
        In addition to simulation studies, empirical evidences are presented to demonstrate the feasibility of
        the proposed variable-length MST.`,
    },
  };
  const personalData = data[speaker];

  useEffect(() => {
    if (!Object.keys(data).includes(speaker)) {
      navigate("/home", { replace: true });
    }
  }, []);

  // Check again to ensure the personalData is valid before rendering

  return (
    <>
      {personalData ? (
        <>
          <DefaultNavbar
            // action={{
            //   type: "internal",
            //   route: loggedin ? "/userinfo" : "/signin",
            //   label: loggedin
            //     ? userInfo.fname + " " + userInfo.lname
            //     : "Sign in", // Conditionally set the label
            //   color: "info",
            // }}
            routes={routes}
            transparent
            light
          />
          <MKBox bgColor="white">
            <MKBox
              minHeight="25rem"
              width="100%"
              sx={{
                backgroundImage: ({
                  functions: { linearGradient, rgba },
                  palette: { gradients },
                }) =>
                  `${linearGradient(
                    rgba(gradients.dark.main, 0.6),
                    rgba(gradients.dark.state, 0.6)
                  )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
              }}
            />
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({
                  palette: { white },
                  functions: { rgba },
                }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              <MKBox component="section" py={{ xs: 6, sm: 12 }}>
                <Container>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    mx="auto"
                  >
                    <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                      <MKAvatar
                        src={personalData.img}
                        alt="Yonsei"
                        size="xxl"
                        shadow="xl"
                      />
                    </MKBox>
                    <Grid container justifyContent="center" py={1}>
                      <Grid
                        item
                        xs={12}
                        md={10}
                        mx={{ xs: "auto", sm: 6, md: 1 }}
                      >
                        <MKBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mb={1}
                        >
                          <MKTypography variant="h2">
                            {personalData.title}
                          </MKTypography>
                        </MKBox>
                        <MKBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mb={1}
                        >
                          <i>
                            <MKTypography variant="h6">
                              {personalData.name}
                            </MKTypography>
                          </i>
                        </MKBox>
                        <MKBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mb={1}
                        >
                          <i>
                            <MKTypography variant="h6">
                              {personalData.affiliation}
                            </MKTypography>
                          </i>
                        </MKBox>
                        <MKBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={1}
                        >
                          <MKTypography variant="body1">
                            {personalData.abstract
                              .split("<newLine>")
                              .map((line, index) => (
                                <>
                                  {line}
                                  <br />
                                </>
                              ))}
                          </MKTypography>{" "}
                        </MKBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </MKBox>
            </Card>
            {/* <Contact /> */}
            <MKBox pt={6} px={1} mt={6}>
              <DefaultFooter content={footerRoutes} />
              {/* <Footer /> */}
            </MKBox>
          </MKBox>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default IndividualKeyNoteTeam;
