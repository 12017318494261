import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DirectionsSubwayIcon from "@mui/icons-material/DirectionsSubway";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import HotelIcon from "@mui/icons-material/Hotel";
function TravelBlock() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h3" my={1}>
              VISA Information
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={1}>
              If your travel to South Korea requires a VISA, please visit (
              <a
                href="https://www.k-eta.go.kr/portal/apply/index.do?locale=EN"
                style={{ color: "blue" }}
              >
                Official Korea Electronic Travel Authorization Website, Korea
                Immigration Service &lt; K-ETA
              </a>
              )<br />
              <strong>
                <small>
                  * If a tourist visa is not issued on the website, please refer
                  to the website of the embassy or consulate of the respective
                  country.
                </small>
              </strong>{" "}
              <br />
              <strong>
                <small>
                  ** To obtain a visa, if you require a support letter, please
                  contact us at{" "}
                  <a href="mailto:7castle42@gmail.com">(7castle42@gmail.com)</a>{" "}
                  or
                  <a href="mailto:ryoox001@yonsei.ac.kr">
                    (ryoox001@yonsei.ac.kr)
                  </a>
                  , and we will provide the necessary documentation.
                </small>
              </strong>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="h3" my={1}>
              Hotel Accommodations
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Special room rates have been arranged with a number of hotels of
              different categories for the 2024 IACAT Conference. And Yonsei
              University is about a 10-minute drive from the hotel, and a
              shuttle bus is provided.
              <br /> <br />
              Please visit our travel page for more details.
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
          >
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <HotelIcon />
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Hotels
                  <MKTypography
                    component="a"
                    href="/travel/hotels"
                    variant="body2"
                    color="info"
                    fontWeight="regular"
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        fontSize: "1.125rem",
                        transform: "translateX(3px)",
                        transition:
                          "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round":
                        {
                          transform: "translateX(6px)",
                        },
                    }}
                  >
                    Read more
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="h3" my={1}>
              Transportation Options
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              You can fly to Incheon International Airport (ICN). Form Incheon
              airport, you can reach the Yonsei University by airport bus,
              subway and tax.
              <br /> <br />
              Please click each link to read more about specifics!
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
          >
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <DirectionsSubwayIcon />
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Subwway
                  <MKTypography
                    component="a"
                    href="travel/subway"
                    variant="body2"
                    color="info"
                    fontWeight="regular"
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        fontSize: "1.125rem",
                        transform: "translateX(3px)",
                        transition:
                          "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round":
                        {
                          transform: "translateX(6px)",
                        },
                    }}
                  >
                    Read more
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <AirportShuttleIcon />
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Airport Shuttle
                  <MKTypography
                    component="a"
                    href="travel/bus"
                    variant="body2"
                    color="info"
                    fontWeight="regular"
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        fontSize: "1.125rem",
                        transform: "translateX(3px)",
                        transition:
                          "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round":
                        {
                          transform: "translateX(6px)",
                        },
                    }}
                  >
                    Read more
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="info"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <LocalTaxiIcon />
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  Taxi
                  <MKTypography
                    component="a"
                    href="travel/taxi"
                    variant="body2"
                    color="info"
                    fontWeight="regular"
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        fontSize: "1.125rem",
                        transform: "translateX(3px)",
                        transition:
                          "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round":
                        {
                          transform: "translateX(6px)",
                        },
                    }}
                  >
                    Read more
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TravelBlock;
