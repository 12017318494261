import axios from "api/axios";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

const validateUserName = "/user/usernameValidation";

const resetPass = "/user/passwordReset";

const ResetPassForm = ({ email }) => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [pwdStrengthErr, setPwdStrengthErr] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [registering, setRegistering] = useState(false);
  const userRef = useRef < HTMLInputElement > null;
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const [pwdMatchError, setPwdMatchError] = useState(null);
  const handlePassword = (password) => {
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (!password.match(lowerCase)) {
      setPwdStrengthErr("Password should contains lowercase letters!");
    } else if (!password.match(upperCase)) {
      setPwdStrengthErr("Password should contain uppercase letters!");
    } else if (!password.match(numbers)) {
      setPwdStrengthErr("Password should contains numbers also!");
    } else if (password.length < 10) {
      setPwdStrengthErr("Password length should be more than 10.");
    } else {
      setPwdStrengthErr("");
    }
  };
  const validatePassword = (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      setPwdMatchError("Passwords do not match");
    } else {
      setPwdMatchError(null);
    }
  };

  const handlePasswordReset = async () => {
    setRegistering(true);
    setErrMsg("");
    if (pwd === "") {
      setErrMsg("Please provide all information before verifing");
    } else if (pwdStrengthErr !== "") {
      setPwdStrengthErr(String("Password does not satisfy all conditions"));
    } else {
      try {
        const response = await axios.post(resetPass, {
          password: pwd,
          email: email,
        });
        navigate("/signin", { replace: true });
        setRegistering(false);
      } catch (err) {
        setRegistering(false);
        if (err.response) {
          if (err.response.status) {
            setErrMsg(
              "Something went wrong. Please try again at a later time."
            );
            setRegistering(false);
          }
        } else {
          setErrMsg("Something went wrong. Please try again at a later time.");
          setRegistering(false);
        }
      }
    }
  };
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-3 py-12 lg:px-2">
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
        {registering ? (
          <div className="flex justify-center items-center h-40">
            <div
              className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">{"Loading"}...</span>
            </div>
          </div>
        ) : (
          <div className="space-y-2 px-2">
            {errMsg && (
              <div
                className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div>{errMsg}</div>
              </div>
            )}
                <MKBox>
                  <MKInput
                    type="email"
                    label="Email"
                    fullWidth
                    value={email}
                    disabled
                  />
                </MKBox>
            <div>
              {pwdMatchError && (
                <div
                  className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div>{pwdMatchError}</div>
                </div>
              )}
              {pwdStrengthErr && (
                <div
                  className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div>{pwdStrengthErr}</div>
                </div>
              )}
              <MKBox mt={2}>
                <MKInput
                  type="password"
                  label="password"
                  fullWidth
                  onChange={(e) => {
                    setPwd(e.target.value);
                    validatePassword(e.target.value, pwdConfirm);
                    handlePassword(e.target.value);
                  }}
                  value={pwd}
                  required
                />
              </MKBox>
              <MKBox mt={2}>
                <MKInput
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  onChange={(e) => {
                    setPwdConfirm(e.target.value);
                    validatePassword(pwd, e.target.value);
                  }}
                  value={pwdConfirm}
                  required
                />
              </MKBox>
              <p
                className="block text-sm font-medium leading-6 text-gray-900"
                style={{ display: "inline-block", cursor: "pointer" }}
                onMouseEnter={() => setShowPasswordRequirements(true)}
                onMouseLeave={() => setShowPasswordRequirements(false)}
              >
                <small>
                  <i>
                    <u>Password Requirments</u>
                  </i>
                </small>
              </p>
              <div className="flex items-center justify-between relative">
                {showPasswordRequirements && (
                  <div
                    className="password-requirements"
                    style={{
                      display: "inline-block",
                      width: "100%", // Make it as wide as the parent element
                      padding: "8px",
                      background: "white",
                      borderRadius: "8px", // Rounded corners
                      boxShadow: "10px 10px 20px 10px rgba(6, 95, 70, 0.2)", // Shadow
                      position: "absolute",
                      zIndex: 1,
                      top: "20px", // Adjust the distance from the paragraph
                      left: 0,
                    }}
                  >
                    <p>Password should contains lowercase letters!</p>
                    <p>Password should contain uppercase letters!</p>
                    <p>Password should contains numbers also!</p>
                    <p>Password length should be more than 10.</p>
                  </div>
                )}
              </div>
            </div>

            <MKBox pt={2} mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handlePasswordReset}
              >
                Set Password
              </MKButton>
            </MKBox>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassForm;
