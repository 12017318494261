/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";

import Breadcrumbs from "examples/Breadcrumbs";

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import l71 from "assets/images/l71.png";
import l72 from "assets/images/l72.png";

function createData(roomtype, rate, remarks) {
  return { roomtype, rate, remarks };
}

const rows = [
  createData("Standard twin", "190 USD *", "Inclusive of 10% VAT"),
  createData("Suite", "340 USD *", "Inclusive of 10% VAT"),
];

const itemData = [
  {
    img: l71,
    title: "l71",
  },
  {
    img: l72,
    title: "l72",
  },
];

function L7HotelInfo() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={0}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <Breadcrumbs
                      routes={[
                        { label: "Home", route: "/home" },
                        {
                          label: "Travel",
                          route: "/pages/travel",
                        },
                        { label: "L7 Hotel" },
                      ]}
                    />
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      py={2}
                    >
                      <MKTypography variant="h3">L7 Hotel</MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      py={1}
                    >
                      <MKTypography variant="h6">
                        IACAT 2024 Discounted Rate Hotel Website{" "} <br />
                        <a
                          href="https://www.lottehotel.com/hongdae-l7/en/hotel-offers/packages/corporate/iacat-2024.html?promotionCode=COFCHR"
                          target="_blank"
                          style={{"color":"blue"}}
                        >
                          https://www.lottehotel.com/hongdae-l7/en/hotel-offers/packages/corporate/iacat-2024.html?promotionCode=COFCHR
                        </a>
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      py={1}
                    >
                      <MKTypography variant="h6">
                        Hotel Website{" "} <br />
                        <a
                          href="https://www.lottehotel.com/hongdae-l7/en/information.html"
                          target="_blank"
                          style={{"color":"blue"}}
                        >
                          https://www.lottehotel.com/hongdae-l7/en/information.html
                        </a>
                      </MKTypography>
                    </MKBox>
                    <ImageList cols={2} rowHeight={164}>
                      {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                          <img
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                    <br />
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead style={{ display: "contents" }}>
                          <TableRow style={{ display: "table-header-group;" }}>
                            {" "}
                            <TableCell>Room Type</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Remarks</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.roomtype}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {" "}
                              <TableCell component="th" scope="row">
                                {row.roomtype}
                              </TableCell>
                              <TableCell align="left">{row.rate}</TableCell>
                              <TableCell align="left">{row.remarks}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <small>
                      Reservations for the discounted price are only available
                      from 2024/9/22~2024/9/29.
                    </small>
                    <br />
                    <small>
                      <small>
                        * Prices may alter depending on the date selection.
                      </small>
                    </small>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default L7HotelInfo;
