/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Routes
import routes from "routes";

import Card from "@mui/material/Card";

import Breadcrumbs from "examples/Breadcrumbs";

function SubwayInstructions() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container justifyContent="center" py={0}>
                  <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <Breadcrumbs
                      routes={[
                        { label: "Home", route: "/home" },
                        {
                          label: "Travel",
                          route: "/pages/travel",
                        },
                        { label: "Subway Travel" },
                      ]}
                    />
                    <MKBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                      py={2}
                    >
                      <MKTypography variant="h3">
                        Subway Travel information
                      </MKTypography>
                    </MKBox>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      To get from Incheon International Airport to Yonsei
                      University in Seoul by subway, you can follow these steps:
                    </MKTypography>
                    <MKTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                      mt={2}
                    >
                      <ol>
                        <li>
                          Arrival at Incheon Airport: After arriving at Incheon
                          International Airport, follow the signs to the Airport
                          Railroad (AREX) station. You can find it in the
                          airport's transportation center.
                        </li>
                        <li>
                          Purchase a Ticket: Buy a one-way ticket for the
                          Airport Railroad (AREX) Line. The fare may vary
                          depending on your destination, but you'll be heading
                          towards Hongik University Station.
                        </li>
                        <li>
                          Board the Airport Railroad (AREX) Express Train: The
                          Airport Railroad (AREX) offers two types of trains,
                          Express and All Stop. You can take the Express train,
                          which is faster and more convenient. Make sure you are
                          on the train heading towards Hongik University
                          Station.
                        </li>
                        <li>
                          Travel to Hongik University Station: The AREX Express
                          train will take you directly to Hongik University
                          Station. The journey takes approximately 1 hour,
                          depending on the airport terminal you arrive at
                          (Terminal 1 or Terminal 2).
                        </li>
                        <li>
                          Transfer to Line 2 (Green Line): At Hongik University
                          Station, you will need to transfer to Line 2 of the
                          Subway, which is also known as the Green Line.
                        </li>
                        <li>
                          Ride Line 2 (Green Line): Board Line 2 (Green Line)
                          heading towards Sinchon Station.
                        </li>
                        <li>
                          Arrive at Sinchon Station: Get off the subway at
                          Sinchon Station.
                        </li>
                        <li>
                          Walk to Yonsei University: From Sinchon Station, you
                          can walk to Yonsei University's main campus. The
                          University is within walking distance from the
                          station, and you can follow local signs or map app.{" "}
                        </li>
                      </ol>
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>{" "}
      </MKBox>
    </>
  );
}

export default SubwayInstructions;
