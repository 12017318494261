import { useEffect, useState } from "react";
import SignUpForm from "./signupform";
import "../../index.css";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import ResetPassForm from "./resetpassform";

const OPTInputComponentForSignUp = ({
  otpCode,
  email,
  reset,
  isReset = false,
}) => {
  const otp = otpCode;
  const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
  const [disable, setDisable] = useState(true);
  const [timerCount, setTimer] = useState(60);
  const [errMsg, setErrMsg] = useState("");

  const [verified, setVerified] = useState(false);

  const verfiyOTP = () => {
    if (parseInt(OTPinput.join("")) === otp) {
      setVerified(true);
      setTimer(0);
      setDisable(true);
      setErrMsg("");
    } else {
      setErrMsg("Invalid one time passcode");
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    //cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  return (
    <div>
      <div className="mt-2">
        {errMsg && (
          <div
            className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <svg
              aria-hidden="true"
              className="flex-shrink-0 inline w-5 h-5 mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{errMsg}</div>
          </div>
        )}
        {verified ? (
          <div>
            {isReset ? (
              <ResetPassForm email={email} />
            ) : (
              <SignUpForm email={email} />
            )}
          </div>
        ) : (
          <div>
            <div className="flex flex-col space-y-8">
              <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                <div className="w-16 h-16 ">
                  <input
                    maxLength={1}
                    className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name=""
                    id=""
                    onChange={(e) =>
                      setOTPinput([
                        Number(e.target.value),
                        OTPinput[1],
                        OTPinput[2],
                        OTPinput[3],
                      ])
                    }
                  ></input>
                </div>
                <div className="w-16 h-16 ">
                  <input
                    maxLength={1}
                    className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name=""
                    id=""
                    onChange={(e) =>
                      setOTPinput([
                        OTPinput[0],
                        Number(e.target.value),
                        OTPinput[2],
                        OTPinput[3],
                      ])
                    }
                  ></input>
                </div>
                <div className="w-16 h-16 ">
                  <input
                    maxLength={1}
                    className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name=""
                    id=""
                    onChange={(e) =>
                      setOTPinput([
                        OTPinput[0],
                        OTPinput[1],
                        Number(e.target.value),
                        OTPinput[3],
                      ])
                    }
                  ></input>
                </div>
                <div className="w-16 h-16 ">
                  <input
                    maxLength={1}
                    className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    name=""
                    id=""
                    onChange={(e) =>
                      setOTPinput([
                        OTPinput[0],
                        OTPinput[1],
                        OTPinput[2],
                        Number(e.target.value),
                      ])
                    }
                  ></input>
                </div>
              </div>
              <MKBox mt={4} mb={1} px={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={verfiyOTP}
                >
                  Verify email
                </MKButton>
              </MKBox>
              <div className=" space-y-5">
                <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Didn't recieve code?</p>{" "}
                  <a
                    className="flex flex-row items-center"
                    style={{
                      color: disable ? "gray" : "blue",
                      cursor: disable ? "none" : "pointer",
                      textDecorationLine: disable ? "none" : "underline",
                    }}
                    onClick={() => {
                      if (!disable) {
                        reset();
                        setTimer(60);
                        setDisable(true);
                      }
                    }}
                  >
                    {disable
                      ? `Resend OTP in ${timerCount} seconds`
                      : "Resend OTP"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OPTInputComponentForSignUp;
