import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Images
import Carousel from "react-material-ui-carousel";
import { Paper as CarouselPaper } from "@mui/material";

import first from "../../../assets/samplepics/DSC06496.jpg";
import second from "../../../assets/samplepics/DSC06008-Enhanced-NR.jpeg";
import third from "../../../assets/samplepics/DSC06026-Enhanced-NR.jpeg";
import fourth from "../../../assets/samplepics/DSC06057-Enhanced-NR.jpg";
import fifth from "../../../assets/samplepics/DSC06133-Enhanced-NR.jpg";
import sixth from "../../../assets/samplepics/DSC06177-Enhanced-NR.jpg";
import seventh from "../../../assets/samplepics/DSC06932-Enhanced-NR.jpg";
import eight from "../../../assets/samplepics/DSC08456-Enhanced-NR.jpg";

const carouselItems = [
  {
    src: first,
    alt: "1",
  },
  {
    src: second,
    alt: "2",
  },
  {
    src: third,
    alt: "3",
  },
  {
    src: third,
    alt: "3",
  },
  {
    src: fourth,
    alt: "4",
  },
  {
    src: fifth,
    alt: "5",
  },
  {
    src: sixth,
    alt: "6",
  },
  {
    src: seventh,
    alt: "7",
  },
  {
    src: eight,
    alt: "8",
  },
];
function PhotoBlock() {
  return (
    <MKBox component="section" py={1}>
      <Container>
        <Grid justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} lg={8} sx={{ mb: 3 }}>
            <MKTypography variant="h2" color="info" textGradient>
              Conference Photos
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Carousel
              sx={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                mb: 3,
              }}
            >
              {carouselItems.map((item, index) => (
                <CarouselPaper key={index}>
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ width: "100%", height: "auto" }}
                  />
                </CarouselPaper>
              ))}
            </Carousel>
            <MKTypography variant="body1" color="text" textAlign="left">
              Check out the photo collection from the IACAT 2024 conference{" "}
              <a target="_blank" href="https://drive.google.com/drive/folders/1WvKe_Lv7dpQKS8pLvyKPKTOY_nEwli3h?usp=sharing" style={{ color: "blue" }}>
                here
              </a>
              .
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PhotoBlock;
