/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import yonseiEmblem from "assets/images/YonseiUniversityEmblem.png";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Card from "@mui/material/Card";
import routes from "routes";

import { useState } from "react";
import { jwtDecode } from "jwt-decode";

import sponsorform from "assets/forms/2024 IACAT Conference Sponsorship Opportunities.pdf"

function SponsorshipInfo() {
  const [loggedin] = useState(localStorage.getItem("STATUS"));
  const storedToken = localStorage.getItem("ACCESS_TOKEN");
  const userInfo = storedToken ? jwtDecode(storedToken) : null;
  return (
    <>
      <DefaultNavbar
        // action={{
        //   type: "internal",
        //   route: loggedin ? "/userinfo" : "/signin",
        //   label: loggedin ? userInfo.fname + " " + userInfo.lname : "Sign in", // Conditionally set the label
        //   color: "info",
        // }}
        routes={routes}
        transparent
        light
      />{" "}
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url("https://i.pinimg.com/originals/36/4a/aa/364aaa82f65a7f7df03f0ff087356c79.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={{ xs: 6, sm: 12 }}>
            <Container>
              <Grid container item xs={12} justifyContent="center" mx="auto">
                <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={yonseiEmblem}
                    alt="Yonsei"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  <Grid container justifyContent="center" py={0}>
                    <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                      >
                        <MKTypography variant="h3">
                          Sponsorship Opportunities
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mb={4}
                      >
                        Four levels of sponsorship of IACAT conferences are
                        available. These are described below:
                      </MKTypography>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                      >
                        <MKTypography variant="h5">
                          Platinum Level (USD$10,000)
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mt={2}
                      >
                        <ol>
                          <li>
                            {" "}
                            1. Your sponsorship will be explicitly acknowledged
                            during the presidential address
                          </li>
                          <li>
                            {" "}
                            2. You will be able to include company literature in
                            the attendee registration area{" "}
                          </li>
                          <li>
                            {" "}
                            3. Your promotional materials will be displayed in
                            the rest area
                          </li>
                          <li>
                            {" "}
                            4. Your logo will be shown prominently at the bottom
                            of the initial session announcement screen of
                            plenary sessions (presidential address, and keynote
                            speakers)
                          </li>
                          <li>
                            {" "}
                            5. Your logo will be shown prominently on the
                            conference website and will redirect a click on logo
                            to sponsor web site
                          </li>
                          <li>
                            {" "}
                            6. You may include a 200-word profile of your
                            organization for the website
                          </li>
                          <li>
                            {" "}
                            7. Your logo will be displayed in the conference app
                            (if supported by the app)
                          </li>
                        </ol>
                      </MKTypography>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={4}
                        mb={1}
                      >
                        <MKTypography variant="h5">
                          Gold Level (USD$5,000)
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mt={2}
                      >
                        <ol>
                          <li>
                            {" "}
                            1. You will be able to include company literature in
                            the attendee registration area
                          </li>
                          <li>
                            {" "}
                            2. Your logo will be shown prominently at the bottom
                            of the initial session announcement screen of
                            plenary sessions (presidential address, and keynote
                            speakers)
                          </li>
                          <li>
                            {" "}
                            3. Your logo will be shown prominently on the
                            conference website and will redirect a click on logo
                            to sponsor web site
                          </li>
                          <li>
                            {" "}
                            4. You may include a 100-word profile of your
                            organization for the website
                          </li>
                          <li>
                            {" "}
                            5. Your logo will be displayed in the conference app
                            (if supported by the app)
                          </li>
                        </ol>
                      </MKTypography>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={4}
                        mb={1}
                      >
                        <MKTypography variant="h5">
                          Silver Level (USD$3,000)
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mt={2}
                      >
                        <ol>
                          <li>
                            {" "}
                            1. Your logo will be shown prominently at the bottom
                            of the initial session announcement screen of
                            plenary sessions (presidential address, and keynote
                            speakers)
                          </li>
                          <li>
                            {" "}
                            2. Your logo will be shown prominently on the
                            conference website and will redirect a click on logo
                            to sponsor web site
                          </li>
                          <li>
                            {" "}
                            3. You may include a 75-word profile of your
                            organization for the website
                          </li>
                          <li>
                            {" "}
                            4. Your logo will be displayed in the conference app
                            (if supported by the app)
                          </li>
                        </ol>
                      </MKTypography>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={4}
                        mb={1}
                      >
                        <MKTypography variant="h5">
                          Bronze Level (USD$1,000)
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mt={2}
                      >
                        <ol>
                          <li>
                            {" "}
                            1. Your logo will be shown prominently at the bottom
                            of the initial session announcement screen of
                            plenary sessions (presidential address, and keynote
                            speakers)
                          </li>
                          <li>
                            {" "}
                            2. Your logo will be shown prominently on the
                            conference website and will redirect a click on logo
                            to sponsor web site
                          </li>
                          <li>
                            {" "}
                            3. You may include a 50-word profile of your
                            organization for the website
                          </li>
                          <li>
                            {" "}
                            4. Your logo will be displayed in the conference app
                            (if supported by the app)
                          </li>
                        </ol>
                      </MKTypography>
                      <MKBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={4}
                        mb={1}
                      >
                        <MKTypography variant="h5">
                          Sponsorship Process
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mt={2}
                      >
                        In order to confirm your sponsorship, please complete
                        the <a href={sponsorform} style={{"color": "blue"}}>Agreement to Sponsor Form </a>no later than 31 July, 2024.
                      </MKTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default SponsorshipInfo;


